<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondAlertsTable.vue
Description:this file gives the info about the alert for devices and its battery levels in the ponds
-->
<template v-loading="loading">
  <er-card class="pond-alerts-table" shadow="never">
    <template slot="header">
      <layout-toolbar class="pond__header" gap="5">
        <p class="pond-alerts-table__header card-title">
          {{ $t("Comn_ponds") }} {{ $t("Comn_alerts") }}
        </p>
        <div class="filler"></div>
        <er-button
          :class="{
            'no-padding': true,
            'action-icon': true,
            'history-button': !isHistoryAlertActive,
            'history-button-active': isHistoryAlertActive
          }"
          btnType="science"
          icon="el-icon-back"
          type="text"
          :showIcon="false"
          @click="handleHistory"
          >History</er-button
        >
        <er-date-picker
          size="mini"
          :timeZoneString="getUserTimeZoneString"
          popper-class="card-chart__date-picker"
          :value="selectedDate"
          :format="upm__getFormatDateString"
          :value-format="datePickerFormat"
          :arrShortcuts="arrDatePickerShortCuts"
          @input="handleChangeInDateRange"
        />
        <er-button
          class="no-padding action-icon"
          btnType="refresh"
          type="text"
          :showIcon="true"
          @click="initComponent(queryParams, 'refresh')"
        ></er-button>
        <div @click="handleOpenDialog">
          <span class="material-icons-outlined action-icon"> queue </span>
        </div>
        <er-dropdown
          placement="bottom"
          @item-click="handleTableDataDownload"
          trigger="click"
          class="highlight"
          :listOfItems="['Comn_download_as_excel', 'Comn_download_as_pdf']"
        >
          <template class="el-dropdown-link download_button" slot="suffix">
            <span class="material-icons-outlined action-icon">
              file_download
            </span>
          </template>
          <template v-slot:item-title="{ item }">{{ $t(item.value) }}</template>
        </er-dropdown>
        <!-- <er-list-filter
          :data="arrPondsForSelection"
          value-key="pond_id"
          class="highlight"
          search-on-key="label"
          :defaultCheckedKeys="getAvailablePondIds"
          @check-change="handleChangeInSelectedPonds"
        >
          <template v-slot:label="{ item }">{{ item.label }}</template>
        </er-list-filter> -->
      </layout-toolbar>
      <div class="divider" />
    </template>
    <el-row v-loading="loading">
      <Loader v-if="loading"></Loader>
      <er-data-table-server
        ref="table"
        v-show="!loading"
        unique-id="pond-alerts-table__table"
        :key="$i18n.locale"
        :merge="{ startColumnNumber: 1, endColumnNumber: 3 }"
        :tableData="tableDataComputed"
        :columns="tableColumns"
        :page-size="$constants.table['page-size']"
        :pagination-props="$constants.table['pagination-props']"
        :total="totalRecords"
        :disable-check="false"
        :tableProps="getTableProps"
        @loadData="initComponent"
        :action-column="false"
        type="white-header-table"
      >
        <template v-slot:pond="{ row }">
          <er-highlight-search-component
            :highlightStrings="row.data.highlightStrings"
            :individualRowData="row.data"
            keyName="pond"
            :withoutHighlightStrings="row.data.pond_name"
          />
        </template>

        <template v-slot:device_type="{ row }">
          {{ $tc($constants.deviceTypes[row.data.device_type].lang_key, 1) }}
        </template>
        <template v-slot:device_id="{ row }">
          <span :title="row.data.device_code">
            <template>
              {{ upm__getValue(row.data) }}
            </template>
          </span>
        </template>
        <template v-slot:status="{ row }">
          <!-- <template v-for="(alert,index) in row.data.arr_error_alerts"> -->
          <el-tooltip
            :content="renderAlertName(row.data.error_code)"
            placement="bottom"
            popperClass="pond-alerts-table-el-tooltip__popper"
            effect="light"
          >
            <img rel="prerender" :src="renderAlertIcon(row.data.error_code)" />
          </el-tooltip>
          <!-- </template> -->
        </template>
        <template v-slot:duration="{ row }">
          <span>{{ durationHandle(row.data, 'table') }}</span>
        </template>
        <template v-slot:utc_start_date="{ row }">
          <span>{{ formatDate(row.data, "utc_start_date") }}</span>
        </template>
        <template v-slot:utc_end_date="{ row }">
          <span>{{ formatDate(row.data, "utc_end_date") }}</span>
        </template>
        <template v-slot:voltage="{ row }">
          <span :style="generateColorForBatterVoltage(row.data)">
            <span>{{ ftm__formatEmpty(ftm__digitPrecision(batteryVoltage(row.data))) }}</span>
          </span>
        </template>
        <template v-slot:resolved_by="{ row }">
          <er-select
            size="mini"
            v-model="row.data.resolved_by"
            width="220px"
            class="dropdown-style"
            :clearable="true"
            value-key="code"
            autocomplete="new-country"
            :disabled="disabled"
            filterable
            @change="handleResolvedChange(row.data, 'resolved_by')"
          >
            <er-option
              v-for="(user, index) in subUsersData || []"
              :key="index"
              :label="ftm__capitalize(user.name)"
              :value="user.value"
              :disabled="user.disabled"
            ></er-option>
          </er-select>
        </template>
        <template v-slot:user_timezone_resolve_date="{ row }">
          <el-date-picker
            size="mini"
            width="10%"
            :clearable="true"
            v-model="row.data.user_timezone_resolve_date"
            type="datetime"
            format="dd-MMM-yy HH:mm"
            value-format="yyyy-MM-dd[T]HH:mm:ss.SSS[Z]"
            class="date-picker-style"
            popper-class="date-picker"
            :picker-options="fromDatePickerOptions"
            @change="handleResolvedChange(row.data, 'resolved_date')"
          />
        </template>
        <template v-slot:resolved_notes="{ row }">
          <el-input
            v-model="row.data.resolved_notes"
            @input="handleResolvedChange(row.data, 'resolved_notes')"
            size="mini"
            type="textarea"
            maxlength="250"
            :autosize="{ minRows: 1, maxRows: 2 }"
            :rows="2"
          ></el-input>
          <!-- <span :style="generateColorForBatterVoltage(row.data)">
            <span>{{ ftm__formatEmpty(batteryVoltage(row.data)) }}</span>
          </span> -->
        </template>
      </er-data-table-server>
    </el-row>
    <!-- <el-row class="btn-row-css">
      <el-col :sm="2" style="display: flex;justify-content: center;"> -->
        <div>
        <er-button
          class="btn-css"
          btnType="save"
          size="mini"
          :showLabel="true"
          :showIcon="true"
          :loading="saveLoading"
          @click="submitUpdatedDetails"
        ></er-button>
      <!-- </el-col>
      <el-col :sm="2"> -->
        <er-button
          class="cancel-btn-css"
          btnType="cancel"
          size="mini"
          :showLabel="true"
          @click="initComponent(queryParams, 'cancel')"
        ></er-button>
        </div>
      <!-- </el-col> -->
      <!-- @click="initComponent" -->
    <!-- </el-row> -->
    <component
      :is="`HistoryAlertsActionDialog`"
      :showDialog="dialogVisible"
      @close_dialog="closeDialog"
      :tabData="tabData"
    ></component>
  </er-card>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import errorAlertsUtils from "@/utils/errorAlertsUtils";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import Loader from "@/components/base/Loader";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin.js";
import exportFileDownload from "@/mixins/exportFileDownload";
import moment from "moment";
import HistoryAlertsActionDialog from "./HistoryAlertsActionDialog.vue"
import ErDataTableServer from '../../base/erDataTableServer.vue';
export default {
  mixins: [
    errorHandlerMixin,
    userPreferenceMixin,
    datesHandlerMixin,
    filtersMixin,
    exportFileDownload
  ],
  name: "PondAlertsTable",
  props: {
    data: Array,
    tabData: Object
  },
  components: {
    Loader,
    HistoryAlertsActionDialog,
    ErDataTableServer
  },
  data: function() {
    return {
      date: [],
      selected_pm: {},
      selectedpond: [],
      selectedAlertTypes: [],
      pmHistory: "",
      total: 0,
      query: {
        limit: 100,
        page: 1
      },
      queryParams: {
        page: 1,
        limit: 10
      },
      loading: true,
      isHistoryAlertActive: true,
      datePickerFormat: "yyyy-MM-dd",
      disabled: false,
      changedTableDataObj: {},
      dataToUpdate: [],
      tableData: [],
      tableDataDownload: [],
      fromDatePickerOptions: {
        disabledDate: this.disabledDate
      },
      saveLoading: false,
      dialogVisible: false
    };
  },
  computed: {
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits",
      getUserTimeZoneString: "getUserTimeZoneString",
      getCurrUserLocation: "getCurrUserLocation",
      getUserProfile: "getUserProfile"
    }),
    ...mapGetters("pondmother", {
      getObjPmIdPm: "getObjPmIdPm"
    }),
    ...mapGetters("alertsInfo", {
      getFilterTableHistoryData: "getFilterTableHistoryData",
      getFilterTableHistoryDataDownload: "getFilterTableHistoryDataDownload",
      getPondsHavingAlerts: "getPondsHavingAlerts",
      getSubUsersData: "getSubUsersData",
      getAlertsHistoryUsersData: "getAlertsHistoryUsersData"
    }),
    tableDataComputed() {
      return this.tableData;
    },
    tableDataDownloadComputed() {
      return this.tableDataDownload;
    },
    totalRecords() {
      return this.total;
    },
    pckerOptions() {
      if (this.$attrs["picker-options"]) return this.$attrs["picker-options"];
      const disabledDateMethod = (time) => {
        return this.dhm__dateUtilsLib.isAfter(
          time,
          this.dhm__dateUtilsLib.getCurrDateInGivenTZ(this.timeZoneString)
        );
      };
      return [{ prop: "disabledDate", value: disabledDateMethod }].reduce(
        (acc, pickerOption) => {
          if (!pickerOption.value) {
            return acc;
          }
          acc[pickerOption.prop] = pickerOption.value;
          return acc;
        },
        {}
      );
    },
    subUsersData() {
      const userType = JSON.parse(localStorage.getItem("curr-user-type"));
      if (userType === "POND_WORKER" || userType === "POND_TECHNICIAN") {
        const subUserData = this.getAlertsHistoryUsersData.reduce(
          (acc, curr) => {
            const user = {
              name: curr.full_name,
              value: curr._id,
              status: curr?.status || "ACTIVE",
              disabled:
                JSON.parse(localStorage.getItem(`${userType}-id`)) !== curr._id
            };
            acc.push(user);
            return acc;
          },
          []
        );
        return subUserData.sort((user1, user2) => {
          return this.$commonUtils
            .alphaNumericComparator(user1.name, user2.name)
        }).filter((user) => user.status === "ACTIVE");
      } else {
        const profileData = this.getUserProfile;
        var subUsersDataCopy = this.$lodash.cloneDeep(this.getSubUsersData);
        var subUserIds = [];
        subUsersDataCopy.forEach((user) => {
          subUserIds.push(user.value);
        });
        if (!subUserIds.includes(profileData._id)) {
          subUsersDataCopy.push({
            name: profileData.full_name,
            value: profileData._id,
            status: profileData.status,
            disabled: false
          });
        }
        return subUsersDataCopy
          .sort((user1, user2) => {
            return this.$commonUtils.alphaNumericComparator(
              user1.name,
              user2.name
            );
          })
          .filter((user) => user.status === "ACTIVE");
      }
    },
    arrDatePickerShortCuts: function() {
      return [
        this.dhm__dateUtilsLib.getDatePickerShortcut(
          0,
          "days",
          this.$t("Comn_today"),
          false,
          "TODAY"
        ),
        this.dhm__dateUtilsLib.getDatePickerShortcut(2, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(7, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(15, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(30, "days"),
        this.dhm__dateUtilsLib.getDatePickerShortcut(2, "months")
      ];
    },
    selectedDate: {
      get() {
        return this.date;
      },
      set(value) {
        this.date = value;
      }
    },
    getDateObjForSelectedDate: function() {
      return [
        this.dhm__dateUtilsLib.toDate(
          this.dhm__dateUtilsLib.utcToZonedTime(
            this.dhm__castUserUTCToUTCISO(new Date(this.selectedDate[0])),
            this.getUserTimeZoneString
          )
        ),
        this.dhm__dateUtilsLib.toDate(
          this.dhm__dateUtilsLib.utcToZonedTime(
            this.dhm__castUserUTCToUTCISO(new Date(this.selectedDate[1])),
            this.getUserTimeZoneString
          )
        )
      ];
    },
    getIsToday() {
      return [
        this.dhm__dateUtilsLib.isEqual(
          this.getDateObjForSelectedDate[0],
          this.dhm__getTodayInUserTZ
        ),
        this.dhm__dateUtilsLib.isEqual(
          this.getDateObjForSelectedDate[1],
          this.dhm__getTodayInUserTZ
        )
      ].every((item) => item === true);
    },

    getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    },
    getTableProps() {
      return {
        border: false,
        stripe: false,
        size: "small",
        height: "calc(100vh - 180px)",
        // defaultSort: {
        //   prop: this.devicePreference[this.upm__getPrefDeviceIdentityKey]
        //     .sort_key,
        //   order: "ascending"
        // }
      };
    },
    // getPondsHavingAlertsSortByTitle() {
    //   return this.$lodash
    //     .cloneDeep(this.getPondsHavingAlerts || [])
    //     .sort((a, b) =>
    //       this.$commonUtils.alphaNumericComparator(a.title, b.title)
    //     );
    // },
    generateColorForBatterVoltage() {
      return (data) => {
        const bv = this.batteryVoltage(data);
        if (!bv) return "#00000";
        if (data.device_type === this.$constants.deviceTypes.POND_GUARD.key) {
          if (bv <= 3.3) {
            return { color: "Red" };
          } else if (bv > 3.3 && bv <= 3.7) {
            return { color: "Orange" };
          } else if (isNaN(bv)) {
            return { color: "Black" };
          } else {
            return { color: "Green" };
          }
        } else if (
          [
            this.$constants.deviceTypes.POND_MOTHER.key,
            this.$constants.deviceTypes.SHRIMP_TALK.key,
            this.$constants.deviceTypes.FEED_BLOWER.key
          ].includes(data.device_type)
        ) {
          if (bv <= 11) {
            return { color: "Red" };
          } else if (bv > 11 && bv <= 12) {
            return { color: "Orange" };
          } else if (isNaN(bv)) {
            return { color: "Black" };
          } else {
            return { color: "Green" };
          }
        }
      };
    },
    // pondsForSelection() {
    //   const ponds = this.getPondsHavingAlertsSortByTitle.reduce((acc, x) => {
    //     acc[x._id] = { pond_id: x._id, label: x.title, default: true };
    //     return acc;
    //   }, {});
    //   return ponds;
    // },
    // getAvailablePondIds() {
    //   return Object.keys(this.pondsForSelection);
    // },
    // arrPondsForSelection() {
    //   return Object.values(this.pondsForSelection);
    // },
    devicePreference: function() {
      return {
        device_code: {
          label: this.$t("Comn_device_id"),
          sort_key: "device_code"
        },
        device_title: {
          label: this.$t("device_name"),
          sort_key: "device_title"
        }
      };
    },

    downloadColumns() {
      return [
        {
          header: this.tableColumns.pond.label,
          dataKey: "pond_name",
          emptyValue: "--",
          name: "pond",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.device_type.label,
          dataKey: (value) => {
            return this.$tc(
              this.$constants.deviceTypes[value.device_type].lang_key,
              1
            );
          },
          emptyValue: "--",
          name: "device_type",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.device_id.label,
          dataKey: (value) => {
            return this.upm__getValue(value);
          },
          emptyValue: "--",
          name: "device_id",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.voltage.label,
          dataKey: "battery_voltage",
          emptyValue: "--",
          name: "voltage",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.status.label,
          dataKey: (value) => {
            return this.renderAlertName(value.error_code);
          },
          emptyValue: "--",
          name: "status",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.utc_start_date.label,
          dataKey: (value) => {
            return (
              value?.utc_start_date &&
              this.formatDateMethod(value.utc_start_date)
            );
          },
          emptyValue: "--",
          name: "utc_start_date",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.utc_end_date.label,
          dataKey: (value) => {
            return (
              value?.utc_end_date && this.formatDateMethod(value.utc_end_date)
            );
          },
          emptyValue: "--",
          name: "utc_end_date",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.duration.label + " (mins)",
          dataKey: (value) => {
            return this.durationHandle(value, 'download');
          },
          emptyValue: "--",
          name: "duration",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.resolved_by.label,
          dataKey: (value) => {
            return this.subUsersData.filter((item) => {
              if (item.value === value.resolved_by) {
                return item;
              }
            })[0]?.name;
          },
          emptyValue: "--",
          name: "resolved_by",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.user_timezone_resolve_date.label,
          dataKey: (value) => {
            return (
              value?.resolved_date && this.formatDateMethod(value.resolved_date)
            );
          },
          // dataKey: "resolved_date",
          emptyValue: "--",
          name: "resolved_date",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 20
        },
        {
          header: this.tableColumns.resolved_notes.label,
          dataKey: "resolved_notes",
          emptyValue: "--",
          name: "resolved_notes",
          skipColumn: false,
          formatters: [],
          xcelcolumnWidth: 2
        }
      ];
    },

    tableColumns() {
      return {
        pond: {
          label: this.$tc("Comn_pond", 0),
          prop: "pond_name",
          column: 2,
          sortable: true
        },
        device_type: {
          label: this.$t("Comn_device_type"),
          prop: "device_type",
          sortable: true,
          minWidth: 120
        },
        device_id: {
          label: this.devicePreference[this.upm__getPrefDeviceIdentityKey]
            .label,
          prop: this.devicePreference[this.upm__getPrefDeviceIdentityKey]
            .sort_key,
          sortable: true,
          minWidth: 120
        },
        voltage: {
          label: this.$t("Comn_voltage"),
          prop: "battery_voltage",
          sortable: false,
          minWidth: 120
        },
        status: {
          label: this.$t("Comn_alert"),
          prop: "user_priority",
          width: 90,
          sortable: false
        },
        utc_start_date: {
          label: this.$t("Comn_start_date"),
          prop: "utc_start_date",
          minWidth: 130,
          sortable: true,
          // sortMethod: (a, b) => {
          //   return this.dhm__dateUtilsLib.dateComparator(a.date, b.date, true);
          // }
        },
        utc_end_date: {
          label: this.$t("Comn_end_date"),
          prop: "utc_end_date",
          minWidth: 130,
          sortable: true,
          // sortMethod: (a, b) => {
          //   return this.dhm__dateUtilsLib.dateComparator(a.date, b.date, true);
          // }
        },
        duration: {
          label: this.$t("Comn_duration"),
          prop: "duration",
          minWidth: 120,
          sortable: true
          // sortMethod: (a, b) => {
          //   return this.dhm__dateUtilsLib.dateComparator(a.date, b.date, true);
          // }
        },
        resolved_by: {
          label: this.$t("Comn_fixed_by"),
          prop: "resolved_by",
          minWidth: 160,
          sortable: false
        },
        user_timezone_resolve_date: {
          label: this.$t("Comn_fixed_date"),
          prop: "user_timezone_resolve_date",
          minWidth: 170,
          sortable: false,
          // sortMethod: (a, b) => {
          //   return this.dhm__dateUtilsLib.dateComparator(a.date, b.date, true);
          // }
        },
        resolved_notes: {
          label: this.$t("Comn_reason"),
          prop: "resolved_notes",
          minWidth: 170,
          sortable: false
        }
      };
    },
    // sortMethodForColumns: function() {
    //   return {
    //     pond_name: this.$commonUtils.alphaNumericComparator,
    //     user_priority: (a, b) => a - b,
    //     sort_device_title: this.$commonUtils.alphaNumericComparator,
    //     sort_device_code: this.$commonUtils.alphaNumericComparator
    //   };
    // },
    formatDate() {
      return (pond, key = null) => {
        if (pond.error_code === "NO_SCHEDULES") return "--";
        return this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          key !== "" ? pond[key] : pond.date,
          this.upm__getFormatDateAndTimeString,
          this.getUserTimeZoneString,
          this.getLocaleObj
        );
      };
    },

    batteryVoltage() {
      return (data) => {
        return data.battery_voltage;
      };
    }
  },
  async mounted() {
    this.$nextTick(() => {
      if (this.$refs.table) {
        this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
    await this.initDate();
    // await this.initComponent(this.queryParams);
    if (window.performance) {
      console.info("window.performance works fine on this browser");
    }
    console.info(performance.getEntriesByType("navigation")[0].type);
    // fix above error
    // alternative property of performance.navigation.type in javascript
    if (performance.getEntriesByType("navigation")[0].type === "reload") {
      localStorage.setItem("AlertHistory", true);
      console.info("This page is reloaded");
    } else {
      console.info("This page is not reloaded");
    }
    // this.tableData = await this.getFilterTableHistoryData.map((item) => {
    //   item.user_timezone_resolve_date = item?.resolved_date
    //     ? this.formatDateMethodTableLevel(item?.resolved_date)
    //     : "";
    //   return item;
    // });
  },
  methods: {
    ...mapActions("alertsInfo", {
      changeAlertsFilterSelection: "changeAlertsFilterSelection",
      fetchAllAlertsHistory: "fetchAllAlertsHistory",
      fetchSubUserDetails: "fetchSubUserDetails",
      patchAlertsHistoryData: "patchAlertsHistoryData"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    getResolvedByDataFormatted(date) {
      if (date) {
        return this.formatDateMethod(date);
      }
    },
    disabledDate(date) {
      return (
        moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z" >
        moment(new Date()).format("YYYY-MM-DD") + "T23:59:59.999Z"
      );
    },
    async downloadDataApiCall() {
      try {
        this.loading = true;
        await this.fetchAllAlertsHistory({ data: this.queryParams, type: "download" });
        this.tableDataDownload = await this.getFilterTableHistoryDataDownload.map((item) => {
          item.user_timezone_resolve_date = item?.resolved_date
            ? this.formatDateMethodTableLevel(item?.resolved_date)
            : "";
          return item;
        });
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    async initComponent(query = { page: 1, limit: 100 }, data = '') {
      this.loading = true;
      this.queryParams = query;
      try {
        if (data === 'refresh') {
          this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History Table - Refresh" });
        }
        if (data === 'cancel') {
          this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History Table - Cancel" });
        }
        if (this.date.length === 0) {
          await this.initDate();
        }
        const userType = JSON.parse(localStorage.getItem("curr-user-type"));
        if (userType !== "POND_WORKER" && userType !== "POND_TECHNICIAN") {
          await this.fetchSubUserDetails();
        }
        this.queryParams.from_date = this.date[0] + "T00:00:00.000Z";
        this.queryParams.to_date = this.date[1] + "T23:59:59.999Z";
        const response = await this.fetchAllAlertsHistory({ data: this.queryParams, type: 'Table' });
        this.total = response.total;
        this.tableData = await this.getFilterTableHistoryData.map((item) => {
          item.user_timezone_resolve_date = item?.resolved_date
            ? this.formatDateMethodTableLevel(item?.resolved_date)
            : "";
          return item;
        });
        console.log("this.tableData", this.tableData);
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.changedTableDataObj = {};
        this.loading = false;
      }
    },
    formatDateMethod(UtcDate) {
      return (
        UtcDate &&
        this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          UtcDate,
          this.upm__getFormatDateAndTimeString,
          this.getUserTimeZoneString,
          this.getLocaleObj
        )
      );
    },
    formatDateMethodTableLevel(UtcDate) {
      return (
        UtcDate &&
        this.dhm__dateUtilsLib.getFormatByTimeZoneOnUTC(
          UtcDate,
          "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
          this.getUserTimeZoneString,
          this.getLocaleObj
        )
      );
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          return this.dhm__dateUtilsLib.isAfter(
            time,
            this.dhm__dateUtilsLib.getCurrDateInGivenTZ(this.timeZoneString)
          );
          // let cultivation_date = (
          //   this.getMapPondidPond.get(this.formHarvestLog.pond_id) || {
          //     cultivation_date: this.dhm__dateUtilsLib.minTimeISO
          //   }
          // ).cultivation_date;
          // if (this.actionToPerform === "edit") {
          //   cultivation_date = this.formHarvestLog.start_of_cultivation;
          // }
          // const computedCultivationDate = this.dhm__dateUtilsLib.utcToZonedTime(
          //   this.dhm__castUserUTCToUTCDateObj(cultivation_date),
          //   this.getUserTimeZoneString
          // );
          // return (
          //   this.dhm__dateUtilsLib.isBefore(time, computedCultivationDate) ||
          //   this.dhm__dateUtilsLib.isAfter(time, this.dhm__getTodayInUserTZ)
          // );
        }
      };
    },
    durationHandle(data, type) {
      if (type === 'download') {
        return (
          Math.trunc(moment.duration(data.duration, "seconds").asMinutes()) +
          " min"
        );
      } else {
        if (moment.duration(data.duration, "seconds").asMinutes() < 60) {
          return (
            Math.trunc(moment.duration(data.duration, "seconds").asMinutes()) +
            " min"
          );
        } else if (
          moment.duration(data.duration, "seconds").asMinutes() > 60 &&
          moment.duration(data.duration, "seconds").asMinutes() <= 1440
        ) {
          return (
            Math.trunc(moment.duration(data.duration, "seconds").asHours()) +
            " hrs"
          );
        } else if (moment.duration(data.duration, "seconds").asMinutes() > 1440) {
          return (
            Math.trunc(moment.duration(data.duration, "seconds").asDays()) +
            " days"
          );
        }
      }
    },
    handleResolvedChange(data, type) {
      // if (!this.changedTableDataObj.hasOwnProperty(data.error_id)) {
      // three value is empty
      // if already pushed remove it
      // three value is empty not add it
      if (
        data.resolved_by ||
        data.user_timezone_resolve_date ||
        data.resolved_notes
      ) {
        this.changedTableDataObj[data.error_id] = true;
      } else if (
        !data.resolved_by &&
        !data.user_timezone_resolve_date &&
        !data.resolved_note
      ) {
        if (this.changedTableDataObj[data.error_id]) {
          delete this.changedTableDataObj[data.error_id];
        }
      }
      // }
    },
    async submitUpdatedDetails() {
      // var PMpayloadData = [];
      // var PGpayloadData = [];
      // var STpayloadData = [];
      // var GWpayloadData = [];
      // console.llog()
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        if (
          Object.keys(this.changedTableDataObj).length > 0 &&
          this.changedTableDataObj
        ) {
          this.loading = true;
          this.saveLoading = true;
          var payloadData = Object.keys(this.changedTableDataObj).reduce(
            (acc, curr) => {
              const data = this.tableDataComputed.find(
                (item) => item.error_id === curr
              );
              if (
                curr === data.error_id &&
                data.resolved_by &&
                data.user_timezone_resolve_date
              ) {
                const payloadObj = {
                  _id: curr,
                  resolved_by: this.tableDataComputed.find(
                    (item) => item.error_id === curr
                  ).resolved_by,
                  resolved_date: this.dhm__castUserUTCToUTCDateObj(
                    this.tableDataComputed.find(
                      (item) => item.error_id === curr
                    ).user_timezone_resolve_date
                  ),
                  resolved_notes: this.tableDataComputed.find(
                    (item) => item.error_id === curr
                  ).resolved_notes,
                  device_type: this.tableDataComputed.find(
                    (item) => item.error_id === curr
                  ).raw_device_type
                };
                acc.push(payloadObj);
              } else if (data.resolved_by && !data.user_timezone_resolve_date) {
                throw {
                  type: "FAIL_TO_SAVE",
                  errors: [
                    {
                      message: this.$t("Comn_fixed_date_empty")
                    }
                  ]
                };
              } else if (!data.resolved_by && data.user_timezone_resolve_date) {
                throw {
                  type: "FAIL_TO_SAVE",
                  errors: [
                    {
                      message: this.$t("Comn_fixed_by_empty")
                    }
                  ]
                };
              } else if (
                !data.resolved_by &&
                !data.user_timezone_resolve_date
              ) {
                throw {
                  type: "FAIL_TO_SAVE",
                  errors: [
                    {
                      message: this.$t("Comn_fixed_by_empty")
                    },
                    {
                      message: this.$t("Comn_fixed_date_empty")
                    }
                  ]
                };
              }
              return acc;
            },
            []
          );
          // var payloadData = this.tableDataComputed.reduce(
          //   (acc, curr) => {
          //     Object.keys(this.changedTableDataObj).forEach((errorId) => {
          //       console.log(errorId === curr.error_id, errorId, curr.resolved_by, curr.user_timezone_resolve_date);
          //       if (
          //         errorId === curr.error_id &&
          //         curr.resolved_by &&
          //         curr.user_timezone_resolve_date
          //       ) {
          //         const payloadObj = {
          //           _id: curr.error_id,
          //           resolved_by: curr.resolved_by,
          //           resolved_date: curr.user_timezone_resolve_date,
          //           resolved_notes: curr.resolved_notes,
          //           device_type: curr.raw_device_type
          //         };
          //         // if (curr.raw_device_type === "POND_GUARD") {
          //         //   PGpayloadData.push(payloadObj);
          //         // } else if (curr.raw_device_type === "POND_MOTHER") {
          //         //   PMpayloadData.push(payloadObj);
          //         // } else if (curr.raw_device_type === "SHRIMP_TALK") {
          //         //   STpayloadData.push(payloadObj);
          //         // } else if (curr.raw_device_type === "GATEWAY") {
          //         //   GWpayloadData.push(payloadObj);
          //         // }
          //         acc.push(payloadObj);
          //       } else if (curr.resolved_by && !curr.user_timezone_resolve_date) {
          //         throw {
          //           type: "FAIL_TO_SAVE",
          //           errors: [
          //             {
          //               message: this.$t("Comn_fixed_date_empty")
          //             }
          //           ]
          //         };
          //       } else if (!curr.resolved_by && curr.user_timezone_resolve_date) {
          //         throw {
          //           type: "FAIL_TO_SAVE",
          //           errors: [
          //             {
          //               message: this.$t("Comn_fixed_by_empty")
          //             }
          //           ]
          //         };
          //       } else if (!curr.resolved_by && !curr.user_timezone_resolve_date) {
          //         throw {
          //           type: "FAIL_TO_SAVE",
          //           errors: [
          //             {
          //               message: this.$t("Comn_fixed_by_empty"),
          //             },
          //             {
          //               message: this.$t("Comn_fixed_date_empty")
          //             }
          //           ]
          //         };
          //       }
          //     });
          //     return acc;
          //   },
          //   []
          // );
          try {
            var PGresponse = {};
            // var PMresponse = {};
            // var STresponse = {};
            // var GWresponse = {};
            if (payloadData.length > 0) {
              PGresponse = await this.patchAlertsHistoryData({
                payload: payloadData
              });
              this.changedTableDataObj = {};
              payloadData = [];
            }
            // if (PMpayloadData.length > 0) {
            //   PMresponse = await this.patchAlertsHistoryData({
            //     device_type: "POND_MOTHER",
            //     payload: PMpayloadData
            //   });
            //   this.changedTableDataObj = {};
            //   PMpayloadData = [];
            // }
            // if (STpayloadData.length > 0) {
            //   STresponse = await this.patchAlertsHistoryData({
            //     device_type: "SHRIMP_TALK",
            //     payload: STpayloadData
            //   });
            //   this.changedTableDataObj = {};
            //   STpayloadData = [];
            // }
            // if (GWpayloadData.length > 0) {
            //   GWresponse = await this.patchAlertsHistoryData({
            //     device_type: "GATEWAY",
            //     payload: GWpayloadData
            //   });
            //   this.changedTableDataObj = {};
            //   GWpayloadData = [];
            // }
            // console.log(PGresponse, PMresponse, STresponse, GWresponse);
            if (Object.keys(PGresponse).length > 0) {
              this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History Table - Save" });
              this.loading = false;
              this.$notify({
                title: this.$t("Usrs_success_msg"),
                message: this.$t("alert_update_success"),
                duration: 5000,
                type: "success"
              });
              console.log("this.queryParams", this.queryParams);
              this.initComponent(this.queryParams, '')
            }
          } catch (err) {
            this.ehm__errorMessages(err, true);
          }
        } else {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_no_changes_found")
              }
            ]
          };
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
        this.loading = false;
      } finally {
        this.loading = false;
        this.saveLoading = false;
      }
    },
    initDate: function() {
      this.date = [
        this.dhm__formatTZ(this.dhm__getTodayInUserTZ, "yyyy-MM-dd"),
        this.dhm__formatTZ(this.dhm__getTodayInUserTZ, "yyyy-MM-dd")
      ];
    },
    getValidDateQueryType(date1, date2, format) {
      // const today = this.dhm__dateUtilsLib.startOfDay(
      //   this.dhm__getTodayInUserTZ
      // );
      const date1ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date1)),
        this.getUserTimeZoneString
      );
      const date2ISO = this.dhm__dateUtilsLib.utcToZonedTime(
        this.dhm__castUserUTCToUTCISO(new Date(date2)),
        this.getUserTimeZoneString
      );
      // calculate difference in days of date1ISO, date2ISO
      const diffInDays = this.dhm__dateUtilsLib.differenceInDays(
        date2ISO,
        date1ISO
      );
      if (diffInDays <= 6) {
        return true;
      } else {
        return false;
      }
      // console.log("diffIndays", diffInDays);
      // const day1 = this.dhm__dateUtilsLib.startOfDay(date1ISO);
      // const day2 = this.dhm__dateUtilsLib.startOfDay(date2ISO);
      // const isBefore = (a, b) => this.dhm__dateUtilsLib.isBefore(b, a);
      // const isAfter = (a, b) => this.dhm__dateUtilsLib.isAfter(b, a);
      // const isEqual = (a, b) => this.dhm__dateUtilsLib.isEqual(b, a);
      // if (isBefore(today, day1) && isBefore(today, day2)) {
      //   return "PAST";
      // } else if (isAfter(today, day1) && isAfter(today, day2)) {
      //   return "FUTURE";
      // } else if (isEqual(today, day1) && isEqual(today, day2)) {
      //   return "TODAY";
      // } else {
      //   throw {
      //     type: "FAIL_TO_SAVE",
      //     errors: [
      //       {
      //         message: this.$t("PM_dont_sel_current_date")
      //       }
      //     ]
      //   };
      // }
    },
    handleChangeInDateRange: async function(date) {
      this.selectedDate = date;
      this.loading = true;
      try {
        const res = this.getValidDateQueryType(...date, "YYYY-MM-DD");
        if (res) {
          this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History Table - Date Filter" });
          this.queryParams.from_date = this.date[0] + "T00:00:00.000Z";
        this.queryParams.to_date = this.date[1] + "T23:59:59.999Z";
        const response = await this.fetchAllAlertsHistory({ data: this.queryParams, type: 'Table' }); // this.initComponent();
        this.total = response.total;
          this.tableData = await this.getFilterTableHistoryData.map((item) => {
            item.user_timezone_resolve_date = item?.resolved_date
              ? this.formatDateMethodTableLevel(item?.resolved_date)
              : "";
            return item;
          });
        } else {
          throw {
            type: "FAIL_TO_SAVE",
            errors: [
              {
                message: this.$t("Comn_difference_in_date_should_be_7")
              }
            ]
          };
        }
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
    handleHistory() {
      // this.$router.push({
      //   name: "alerts-history"
      // });
      this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History Back Btn" });
      this.isHistoryAlertActive = !this.isHistoryAlertActive;
      this.$emit("history", this.isHistoryAlertActive);
    },
    handleChangeInSelectedPonds(selectedPonds) {
      const filter = {
        filter_type: "POND",
        filters: selectedPonds || []
      };
      this.changeAlertsFilterSelection(filter);
    },
    renderAlertIcon(alertCode) {
      return errorAlertsUtils.renderDeviceAlertIcon(alertCode);
    },
    renderAlertName(data) {
      return this.$t(errorAlertsUtils.renderAlertName(data));
    },
    async handleTableDataDownload(downloadtype) {
      await this.downloadDataApiCall();
      this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History Table - Download" });
      if (downloadtype === "Comn_download_as_pdf") {
        await this.pdfdownload();
      } else {
        await this.xceldownload();
      }
    },
    disableDateMethod: function(time) {
      // const soc = this.getMinStartCultureDate;
      const endDate = this.dhm__dateUtilsLib.endOfDay(
        this.dhm__getTodayInUserTZ
      );
      const startDate = this.dhm__dateUtilsLib.startOfDay(
        this.dhm__dateUtilsLib.subtract(endDate, {
          days: 6
        })
      );
      const currDate = time;
      const paramObj = {
        dateRange: [startDate, endDate],
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
      // return false;
    },

    xceldownload() {
      this.exp__Download(
        this.tableDataDownloadComputed,
        this.downloadColumns,
        "excel"
      );
    },
    pdfdownload() {
      const columnWidth = 53;
      this.exp__Download(
        this.tableDataDownloadComputed,
        this.downloadColumns,
        "20column_pdf",
        '',
        columnWidth
      );
    },
    handleOpenDialog() {
      this.dialogVisible = true;
    },
     async closeDialog(event, type) {
      this.dialogVisible = false;
      if (type === 'save') {
        this.mixPanelEventGenerator({ eventName: "Ponds Alerts - History - Group Settings - Save" });
        await this.initComponent(this.queryParams, '');
      }
    }
  }
};
</script>
<style lang="scss">
.el-dialog__body {
  font-size: 13px !important;
  padding: 12px;
  .el-table td,
  .el-table th {
    padding: 0px;
  }
}
.pond-alerts-table {
  overflow: hidden;
  .action-icon {
    cursor: pointer;
    color: black;
    opacity: 0.4;
    font-weight: 500;
    @include icon-size(icon-font);
    &:hover {
      opacity: 1;
    }
  }
  .er-button.er-button--refresh.el-button--text.er-button--icon
    span.material-icons-round {
    @include icon-size(icon-font);
  }
  .alerts-table-options {
    display: flex;
    .er-dropdown {
      margin-right: 12px;
      cursor: pointer;
      .el-icon-download {
        font-size: 20px;
        color: #606266;
        opacity: 0.4;
      }
      .el-icon-download:hover {
        color: #606266;
        opacity: 1;
      }
    }
  }
  .pond__header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
  }
  .el-row--flex {
    padding: 0.5rem;
  }
  &__header {
    font-weight: $font_weight_header;
    color: #0a2463;
    opacity: 0.9;
    @include responsiveProperty(font-size, 1rem, 1.29em, 1.5em);
  }
  .er-data-tables .white-header-table .el-table .el-table__header {
    background-color: transparent;
    border-bottom: 1px solid #ebeef5;
  }
  .el-table {
    border-radius: 0px !important;
    box-shadow: none !important;
    .cell {
      text-align: center;
      font-weight: 500;
    }
  }
  .el-card__body {
    padding: 0px;
  }
  .el-table thead {
    color: #0a2463;
    line-height: 20px;
  }
  .el-table__header th > .cell {
    // height: 30px;
    letter-spacing: 0.3px;
    // text-align: center;
    background-color: white;
    font-weight: 600;
  }
  width: 100%;
  .el-table td {
    padding: 0px;
    .cell {
      line-height: 1;
    }
  }
  .el-dialog__body {
    font-size: 13px !important;
    padding: 12px;
    .el-table td,
    .el-table th {
      padding: 0px;
    }
  }
  .el-table__row {
    .cell {
      img {
        @include responsiveProperty(width, 2em, 2em, 1.8em);
        // margin-top: 5px;
        margin: 0px;
        padding: 0px;
        display: flex;
      }
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    // margin: 0 auto;
    // display: inline-block;
    background-color: #6c7b8a;
  }
  @include responsiveProperty(--height-gutter, 129px, 131px, 150px);
  .history-button {
    display: flex;
    align-items: center;
    border: 1px solid #606266;
    cursor: pointer;
    font-weight: 500;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    span {
      margin: 0 8px;
    }
  }
  .history-button-active {
    display: flex;
    align-items: center;
    color: #fff;
    background-color: #0a2463;
    opacity: 1;
    cursor: pointer;
    font-weight: 500;
    @include responsiveProperty(
      font-size,
      $app_font_size_1,
      $app_font_size_2,
      $app_font_size_3
    );
    span {
      margin: 0 8px;
    }
    i {
      color: #fff !important;
      margin-left: 8px;
    }
  }
}
.download_dropdown {
  color: #606266;
  cursor: pointer;
  .el-dropdown-menu__item {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
}

.pond-alerts-table-el-tooltip__popper {
  border: 1px solid #606266 !important;
  color: #606266 !important;
}

.pond-alerts-table-el-tooltip__popper.is-light[x-placement^="bottom"]
  .popper__arrow {
  border-bottom-color: #606266 !important;
  top: -7px;
}
.date-picker-style {
  width: 160px !important;
}
.btn-css {
  margin-top: -28px;
  position: absolute;
  right: 120px;
}
.cancel-btn-css {
  margin-top: -28px;
  position: absolute;
  right: 45px;
}
.dropdown-style {
  width: 100% !important;
  .el-dropdown-menu__item {
    @include responsiveProperty(
      font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2
    );
  }
}
</style>
