const autoRefreshMixin = {
  data: function() {
    return {
      arm__timerToken: undefined
    };
  },
  beforeDestroy() {
    this.arm__cancelAutoUpdate();
  },
  methods: {
    arm__activateTimer(callBack, refreshIntervalInMins) {
      this.arm__timerToken = setInterval(
        callBack,
        refreshIntervalInMins * 60 * 1000
      );
    },
    arm__cancelAutoUpdate() {
      clearInterval(this.arm__timerToken);
    }
  }
};
export default autoRefreshMixin;
