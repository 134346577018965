<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: deviceWiseAlerts.vue
Description:this  file gives the info about the alerts i.e no communication no schedules  and total alerts etc
-->
<template>
  <er-card class="alerts-bar-charts">
    <el-row>
      <el-row slot="header" class="pond-card-count__header">
        <div class="device-wise-header">
          <p class="pond-card-count__title card-title">
            {{ $t("device_wise_alerts") }}
          </p>
          <!-- :default-checked-keys="['DEVICE_PondMother','DEVICE_PondGuard','DEVICE_ShrimpTalk']" -->
          <er-list-filter
            :data="getDataForFilterList"
            :props="listProp"
            filter-element-type="NESTED"
            :allow-search="false"
            popper-class="device-wise-alerts__list-popper"
            node-key="nodeKey"
            :defaultCheckedKeys="getAvailableAlertsFilter"
            @select-change="handleCheckChange"
          ></er-list-filter>
        </div>
        <div class="divider" />
      </el-row>

      <el-row v-if="!loading">
        <template v-if="Object.keys(filteredDeviceAlerts).length > 0">
          <template v-for="(item, device_type, index) in filteredDeviceAlerts">
            <div class="divider" v-if="index > 0" :key="item.key + 'divider'" />
            <DeviceAlerts
              v-show="!loading"
              :key="item.key"
              :alertTypes="item.types"
              :name="item.name"
              :count="item.count"
              :total-devices-with-alerts="item.total_devices_with_alerts"
              :total-devices="item.total_devices"
              :item="item"
              v-on="$listeners"
            />
          </template>
        </template>
        <layout-center v-else>
          <el-tag>{{ $t("No_alerts") }}</el-tag>
        </layout-center>
      </el-row>
      <div class="loading_class" v-else-if="loading"></div>
    </el-row>
  </er-card>
</template>

<script>
import DeviceAlerts from "./deviceAlerts";
import errorAlertsUtils from "@/utils/errorAlertsUtils";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DeviceWiseAlerts",
  data: function () {
    return {
      selectedDevices: [],
      listProp: {
        label: "title",
        children: "alerts",
        disabled: "disabled"
      }
    };
  },

  props: {
    loading: Boolean
  },
  components: {
    DeviceAlerts
  },
  computed: {
    ...mapGetters("alertsInfo", {
      getFilterPondIdsAlertTypesByDeviceType:
        "getFilterPondIdsAlertTypesByDeviceType",
      getAllPondsAlertTypesByDeviceType: "getAllPondsAlertTypesByDeviceType",
      getFilterAlertGroupByDeviceType: "getFilterAlertGroupByDeviceType"
    }),
    getNodeKeys: function () {
      const arrNodeKeys = [];
      for (const device_type in this.getFilterAlertGroupByDeviceType) {
        arrNodeKeys.push(
          this.getFilterAlertGroupByDeviceType[device_type].map(
            (alert_type) => {
              return `ALERT_${device_type}_${alert_type}`;
            }
          )
        );
      }
      return arrNodeKeys.flat(1);
    },
    filteredDeviceAlerts() {
      return this.getFilterPondIdsAlertTypesByDeviceType;
    },
    getAvailableAlertsFilter() {
      return Object.values(
        this.getFilterPondIdsAlertTypesByDeviceType || {}
      ).reduce((acc, curr) => {
        const arrAlerts = Object.keys(curr.types).map(
          (alert_type) => `ALERT_${curr.device_type}_${alert_type}`
        );
        acc.push(...arrAlerts);
        return acc;
      }, []);
    },
    getDataForFilterList() {
      return Object.values(this.getAllPondsAlertTypesByDeviceType || {}).reduce(
        (acc, curr) => {
          const alertFilterObj = {
            nodeKey: `DEVICE_${curr.device_type}`,
            title: curr.name,
            alerts: Object.keys(curr.types).map((alert_type) => ({
              nodeKey: `ALERT_${curr.device_type}_${alert_type}`,
              title: this.renderAlertName(alert_type),
              alert_type: alert_type,
              device_type: curr.device_type,
              device_name: curr.name
            }))
          };
          alertFilterObj.disabled = alertFilterObj.alerts.length === 0;
          acc.push(alertFilterObj);
          return acc;
        },
        []
      );
    }
  },

  mounted() {},
  methods: {
    ...mapActions("alertsInfo", {
      changeAlertsFilterSelection: "changeAlertsFilterSelection"
    }),
    renderAlertName(data) {
      return this.$t(errorAlertsUtils.renderAlertName(data));
    },
    async handleCheckChange(selectedItem) {
      console.log(selectedItem);
      const selectedFilters = selectedItem
        .filter((item) => item.nodeKey.includes("ALERT"))
        .map(({ alert_type, device_type }) => ({ alert_type, device_type }));
      this.selectedFilters = selectedFilters;
      await this.changeAlertsFilterSelection({
        filter_type: "ALERTS",
        filters: selectedFilters || []
      });
    }
  }
};
</script>

<style lang="scss">
.device-wise-alerts__list-popper {
  left: unset !important;
  right: 25px;
}
.alerts-bar-charts {
  margin-top: 0.6em;
  &.er-card.el-card .el-card__header {
    padding: 5px 10px;
  }
  .loading_class {
    @include responsiveProperty(min-height, 86vh, 88vh, 90vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }

  .pond-card-count__header {
    display: flex;
    flex-direction: column;
    color: #0a2463;
    opacity: 0.9;
    .pond-card-count__title {
      margin-bottom: 6px;
    }
    .device-wise-header {
      display: flex;
      justify-content: space-between;
    }
    .divider {
      width: 100%;
      height: 1px;
      opacity: 0.2;
      margin: 0 auto;
      display: inline-block;
      background-color: #6c7b8a;
    }
  }
  .divider {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    margin: 0 auto;
    display: inline-block;
    background-color: #6c7b8a;
    margin-bottom: 25px;
  }
  .middle-no-data {
    margin-bottom: 10px;
  }
}
</style>
