 <!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: deviceAlerts.vue
Description:this file is child component of the deviceWiseAlerts and shows the data in the progress bar
-->
<template>
  <el-row class="card-device-alert">
    <er-dialog
      width="70%"
      :title="`${getTitle}`"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      @beforeClose="handleCloseDialog"
      custom-class="alerts-clearance-dialog"
    >
      <table>
        <tr style="font-weight: bold">
          <th style="min-width: 160px">
            {{ $t("alerts.possible_cause") }}
          </th>
          <th>{{ $t("alerts.possible_solution") }}</th>
        </tr>

        <tr v-for="(data, index) in dialogContent" :key="index">
          <!-- <div style="display: flex; padding: 10px; word-break: break-word"> -->
          <!-- style="margin-right: 10px; min-width: 210px" -->
          <td>
            <span style="display: flex; align-items: flex-start">
              <span> &#x25FC;</span>&nbsp;
              <span v-html="$t(data.cause)"></span>
            </span>
          </td>

          <td v-if="data.solution.length">
            <span
              style="display: flex; align-items: flex-start"
              v-for="(data, index) in data.solution"
              :key="index"
            >
              <span> &#x25FC;</span>&nbsp;<span v-html="$t(data)"></span>
            </span>
          </td>

          <!-- </div> -->
        </tr>
      </table>
    </er-dialog>
    <el-row class="header-row" type="flex">
      <el-row type="flex" class="col__title col__title--main" align="center">
        <p class="count-title">{{ name }} :</p>
        <p class="count-content">
          {{ `${totalDevicesWithAlerts}/${totalDevices}` }}
        </p>
      </el-row>
    </el-row>

    <el-row
      v-if="Object.keys(alertTypes).length > 0"
      :key="Object.keys(alertTypes).length + 'PM'"
      class="progress-item-container"
    >
      <el-col
        v-for="data in getProgressData"
        :key="data.alertType"
        :percentage="data.percentage"
        :color="data.color"
        class="progress-item"
      >
        <el-col @click.native="handleAlertClick(data)" :span="2">
          <img :src="renderAlertIcon(data.alertType)" />
        </el-col>
        <el-col :span="22">
          <el-row class="progress-item__content">
            <p @click="handleAlertClick(data)" :class="getTitleStyle(data)">
              {{ renderAlertName(data.alertType) }}
            </p>
            &nbsp;
            <span
              v-if="data.alertType!=='LOW_TEMPERATURE' && data.alertType!=='HIGH_TEMPERATURE'"
              class="material-icons-round"
              style="color: #303133; opacity: 0.6"
              @click="renderAlertInfo({ device: name, type: data.alertType })"
              >info</span
            >

            <p class="value" @click="handleAlertClick(data)">
              {{ data.alertCount }}
            </p>
          </el-row>
          <el-progress
            style
            :percentage="data.percentage"
            :color="data.color"
            :show-text="false"
          ></el-progress>
        </el-col>
      </el-col>
    </el-row>
    <div v-else class="middle-no-data">
      <el-tag
        v-if="
          getFirstEmptyAlert(emptyTextHandlers[item.device_type]).validator(
            this
          )
        "
      >
        <i18n
          v-if="
            getFirstEmptyAlert(emptyTextHandlers[item.device_type])
              .emptyTagType === 'LINK'
          "
          :path="getFirstEmptyAlert(emptyTextHandlers[item.device_type]).path"
          :tag="false"
        >
          <template v-slot:action>
            {{
              getFirstEmptyAlert(emptyTextHandlers[item.device_type]).emptyText
                .actionSlot
            }}
          </template>
          <template v-slot:get_result>
            <router-link
              :to="
                getFirstEmptyAlert(emptyTextHandlers[item.device_type]).routeObj
              "
            >
              {{
                getFirstEmptyAlert(emptyTextHandlers[item.device_type])
                  .emptyText.getResultSlot
              }}
            </router-link>
          </template>
        </i18n>
        <template v-else>
          {{
            getFirstEmptyAlert(emptyTextHandlers[item.device_type]).emptyText
          }}
        </template>
      </el-tag>
    </div>
  </el-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import errorAlertsUtils from "@/utils/errorAlertsUtils";
import { deviceTypes } from "@/constants/deviceTypes";
import communicationAlerts from "@/model/communicationAlerts.json";
export default {
  name: "DeviceAlert",
  props: {
    name: String,
    count: Number,
    alertTypes: Object,
    totalDevicesWithAlerts: {
      default: 0
    },
    totalDevices: {
      default: 0
    },
    item: Object
  },
  data: function () {
    return {
      //   percentage: 20,
      dialogContent: [],
      dialogVisible: false,
      customColor: "red",
      selectedAlertTypes: [],
      checkAll: false,
      isIndeterminate: false,
      FILTER_LOCAL_STORE_NAME: {
        PondMother: "ALERT_TYPE_FILTERING_PONDMOTHER",
        PondGuard: "ALERT_TYPE_FILTERING_PONDGUARD",
        ShrimpGuard: "ALERT_TYPE_FILTERING_SHRIMPGUARD",
        FeedBlower: "ALERT_TYPE_FILTERING_FEEDBLOWER"
      }
    };
  },
  mounted() {
    this.selectedAlertTypes = JSON.parse(
      localStorage.getItem(this.FILTER_LOCAL_STORE_NAME[this.name]) || "[]"
    );
  },
  computed: {
    ...mapGetters("alertsInfo", {
      getClickedAlert: "getClickedAlert"
    }),
    getTitle() {
      return this.$t("alerts.stepToClearAlert");
    },
    getProgressData() {
      const list = [];
      for (const property in this.alertTypes) {
        const alertCount = this.alertTypes[property].count;
        let percentage = 0;
        if (this.count > 0 && alertCount) {
          percentage = Math.round((alertCount * 100) / this.count);
        }
        list.push({
          percentage,
          color: "red",
          alertType: property,
          alertCount
        });
      }
      return list;
    },
    filteredProgressData() {
      if (this.selectedAlertTypes.length > 0) {
        return this.getProgressData.filter(({ alertType }) =>
          this.selectedAlertTypes.includes(alertType)
        );
      } else {
        return this.getProgressData;
      }
    },
    emptyTextHandlers: function () {
      const deviceToEmptyTextConfig = {
        [deviceTypes.GATEWAY.key]: [
          {
            validator: (component) => component.totalDevices === 0,
            emptyTagType: "LINK",
            path: "please_do_this_action_to_get_result",
            routeObj: {
              path: "settings",
              query: {
                location_id: this.$route.query.location_id,
                tab: "gateway"
              }
            },
            emptyText: {
              actionSlot: this.$t("actions.click"),
              getResultSlot: this.$t("assign_item_to_source", {
                item: this.$t("Gateway_gateways"),
                source: this.$tc("Comn_location.case.lower", 1)
              })
            }
          },
          {
            validator: (component) =>
              Object.keys(component.alertTypes).length === 0,
            emptyText: this.$t("No_alerts")
          }
        ]
      };
      return deviceToEmptyTextConfig;
    }
  },
  methods: {
    ...mapActions("alertsInfo", {
      selectAlert: "selectAlert"
    }),
    getFirstEmptyAlert(states) {
      if (!states) {
        return {
          validator: (component) =>
            Object.keys(component.alertTypes).length === 0,
          emptyText: this.$t("No_alerts")
        };
      }
      return states.find((x) => x.validator(this));
    },
    renderAlertIcon(alertCode) {
      return errorAlertsUtils.renderDeviceAlertIcon(alertCode);
    },
    renderAlertName(data) {
      return this.$t(errorAlertsUtils.renderAlertName(data));
    },
    handleCheckAllChange(val) {
      this.selectedAlertTypes = val
        ? this.getProgressData.map(({ alertType }) => alertType)
        : [];
      this.isIndeterminate = false;
    },
    handleChangeInSelectedColumns(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.columns.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.columns.length;
    },
    handleSaveDefault() {
      if (this.name === "PondMother") {
        localStorage.setItem(
          "ALERT_TYPE_FILTERING_PONDMOTHER",
          JSON.stringify(this.selectedAlertTypes)
        );
      } else if (this.name === "PondGuard") {
        localStorage.setItem(
          "ALERT_TYPE_FILTERING_PONDGUARD",
          JSON.stringify(this.selectedAlertTypes)
        );
      } else if (this.name === "ShrimpTalk") {
        localStorage.setItem(
          "ALERT_TYPE_FILTERING_SHRIMPTALK",
          JSON.stringify(this.selectedAlertTypes)
        );
      } else {
        localStorage.setItem(
          "ALERT_TYPE_FILTERING_FEEDBLOWER",
          JSON.stringify(this.selectedAlertTypes)
        );
      }
      this.visible = false;
      this.$notify({
        title: this.$t("Usrs_success_msg"),
        message: this.$t("saved-selection-as-defaults"),
        duration: 5000,
        type: "success"
      });
    },
    handleAlertClick(data) {
      const { alertType } = data;
      const { name: deviceName } = this.item;
      const alertData = {
        deviceName,
        alertType
      };
      this.selectAlert(alertData);

      this.$emit("alert-item", alertData);
    },
    getTitleStyle(alert) {
      const { alertType: currentAlertType } = alert;
      const { alertType, deviceName } = this.getClickedAlert;
      const { name: currentDeviceName } = this.item;

      if (alertType === currentAlertType && currentDeviceName === deviceName) {
        return "title alert-selected";
      } else {
        return "title";
      }
    },
    renderAlertInfo(alert) {
      const requiredCommunicationalert = communicationAlerts.reduce(
        (acc, curr) => {
          if (curr.key === alert.type && curr.device === alert.device) {
            acc.push(curr);
          }
          return acc;
        },
        []
      );
      this.dialogContent = requiredCommunicationalert;
      this.dialogVisible = true;
    },
    handleCloseDialog() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.el-dialog {
  // width: 71% !important;
  border-radius: 7px;
  &__body {
    font-size: 13px !important;
  }
}
.alerts-bar-charts {
  .divider {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    margin: 0 auto;
    display: inline-block;
    background-color: #6c7b8a;
    margin-bottom: 15px;
  }
  .card-device-alert {
    // margin-bottom: 25px;
    &:first-child {
      margin-top: 12px;
    }
    .align-left {
      margin-left: auto;
    }
    .progress-item {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      cursor: pointer;
      &:first-child {
        padding-top: 0px;
      }
      img {
        height: 24px;
        width: 24px;
      }
      &__content {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .title {
          opacity: 0.6;
          font-weight: $font_weight_header;
          @include responsiveProperty(
            font-size,
            $app_font_size_small,
            $app_font_size_1,
            $app_font_size_2
          );
        }
        .value {
          margin-left: auto;
          @include responsiveProperty(
            font-size,
            $app_font_size_small,
            $app_font_size_1,
            $app_font_size_2
          );
          font-weight: 600;
        }
      }
      .el-col:first-of-type {
        text-align: center;
      }
      .el-progress-bar__inner {
        opacity: 0.6;
      }
      .alert-selected {
        opacity: 1 !important;
      }
    }
    .header-row {
      align-items: center;
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;
      .col__title {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        color: #0a2463;
        font-weight: 600;
        align-items: center;
        &--main {
          @include responsiveProperty(
            font-size,
            $app_font_size_1,
            $app_font_size_2,
            $app_font_size_3
          );
          opacity: 0.9;
        }
      }
      .count-content {
        @include responsiveProperty(
          font-size,
          $app_font_size_1,
          $app_font_size_2,
          $app_font_size_3
        );
        color: #0a2463;
        margin-left: 12px;
        letter-spacing: 1px;
        font-weight: 600;
      }
    }
    .middle-no-data {
      display: flex;
      justify-content: center;
    }
  }
}
.alerts-clearance-dialog {
  border-radius: 10px;
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
    word-break: break-word;
  }
  tr {
    th {
      color: #0a2463;
      font-weight: 600;
    }
    td {
      span {
        color: #6c7b8a;
      }
    }
  }
}
</style>
